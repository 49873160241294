import { Button, Icon, Modal, notification, Tooltip } from "antd";
import cloneDeep from "lodash/cloneDeep";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Dispatch } from "redux";
import BaseLayout from "../../components/BaseLayout/BaseLayout";
import BabyPrint from "../../components/Cart/BabyPrint";
import Book from "../../components/Cart/Book";
import GiftCard from "../../components/Cart/GiftCard";
import Subscription from "../../components/Cart/Subscription";
import { isEmailValid } from "../../components/SignIn/data/emails";
import { CartItem } from "../../interfaces/cart";
import { Product } from "../../interfaces/product";
import { User } from "../../interfaces/user";
import { AppState, sagaMiddleware } from "../../store/";
import { BaseAction } from "../../store/actionTypes";
import { changeUser } from "../../store/ducks/user";
import queryString from "query-string";
import {
  addProductStore,
  getUserStore,
  updateUserStore,
} from "../../store/sagas/cart";
import "./Cart.scss";
import { StripeProduct } from "../../interfaces";
import moment from "moment";

const { confirm, info } = Modal;

interface Props {
  user: User;
  products: Product[];
  onChangeUser: (payload: any) => void;
  stripeProducts: StripeProduct[];
}

const CartPage: React.FC<Props> = ({
  user,
  products,
  onChangeUser,
  stripeProducts,
}) => {
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [debounce, setDebounce] = useState<NodeJS.Timeout | null>(null);
  const [store, setStore] = useState<CartItem[]>([]);
  const subscriptionOptions = products
    .filter(
      (item) =>
        item.type === "subscription" &&
        !!item.stripe_id &&
        item.description !== "six month",
    )
    .map((item) => {
      return {
        ...item,
        id: item.stripe_id,
        qty: 1,
        price: item.price / 100,
        selected: false,
        sku_id: null,
        sku_type: null,
        sku_image: null,
        sku_attributes: null,
        sku_package_dimensions: null,
        sku_inventory: null,
        shippable: false,
        attributes: null,
        package_dimensions: null,
        active: null,
        metadata: [],
        images: null,
      };
    });

  const cartSummaryMarginClasses =
    user.user_level === 0 ? "summary-position" : "";
  const getDayForSubscription = () => {
    const momentDate = moment().add(1, "week").format("MM/DD/YY");
    return momentDate;
  };
  const addGiftCard = () => {
    const giftCard = stripeProducts.find((p) => p.caption === "giftcard");
    if (!giftCard) {
      return;
    }

    if (!!giftCard) {
      sagaMiddleware.run<any>(
        addProductStore,
        {
          book_id: null,
          babypage_id: null,
          event: "",
          gift: {
            status: true,
            email: "",
            receiver: "",
            message: "",
            date: "",
          },
          id: giftCard.id,
          item: giftCard.type,
          options: [
            {
              id: giftCard.id,
              title: giftCard.name,
              description: giftCard.description,
              shippable: giftCard.shippable,
              attributes: giftCard.attributes,
              package_dimensions: giftCard.package_dimensions,
              active: giftCard.active,
              metadata: giftCard.metadata,
              images: giftCard.images,
              price: giftCard.skus.data[0].price / 100,
              qty: 1,
              sku_id: giftCard.skus.data[0].id,
              sku_type: giftCard.skus.data[0].object,
              sku_image: giftCard.skus.data[0].image,
              sku_attributes: giftCard.skus.data[0].attributes,
              sku_package_dimensions: giftCard.skus.data[0].package_dimensions,
              sku_inventory: giftCard.skus.data[0].inventory,
              selected: true,
            },
          ],
          prices: [],
          qty: 1,
          stripe_id: giftCard.id,
          subtitle: giftCard.name,
          thumbnail: "",
          title: `BabyPage ${giftCard.name}`,
          total: giftCard.skus.data[0].price / 100,
          type: giftCard.caption,
        },
        (error?: string, _?: string) => {
          if (!!error) {
            Modal.error({
              title: "Error",
              content: error,
            });
          }
        },
      );
    }
  };
  useEffect(() => {
    sagaMiddleware.run<any>(getUserStore, (error?: string) => {
      if (!!error) {
        Modal.error({
          title: "Error",
          content: error,
        });
      }
    });
  }, []);

  useEffect(() => {
    if (!!user.store) {
      try {
        const localStore = JSON.parse(user.store);
        if (!!localStore && Array.isArray(localStore)) {
          let newStore: CartItem[] =
            !!user.user_level && user.user_level === 1
              ? cloneDeep(
                  localStore.filter((e: CartItem) => e.type !== "subscription"),
                )
              : cloneDeep(localStore);

          newStore = newStore.map((e) => ({
            ...e,
            options: e.options.sort(function (a, b) {
              return a.price - b.price;
            }),
          }));

          setStore(
            newStore.map((e) => ({
              ...e,
              qty:
                !!e.qty && typeof e.qty === "number" && !isNaN(e.qty)
                  ? e.qty
                  : 1,
            })),
          );
        }
      } catch (error) {}
    }
  }, [user]);

  useEffect(() => {
    if (!!user.user_level && user.user_level === 1) return;

    if (location.pathname.indexOf("/subscription") !== -1) {
      // If user has a subscription in cart dont override cart
      if (!!user.store) {
        let subscription = JSON.parse(user.store).filter(
          (item: CartItem) => item.type === "subscription",
        );
        if (subscription.length) {
          return;
        }
      }
      setLoading(true);
      let product: Product | undefined = undefined;

      if (location.pathname.indexOf("/yearly") !== -1) {
        product = products.find((p) => p.description === "one year");
      } else if (location.pathname.indexOf("/monthly") !== -1) {
        product = products.find((p) => p.description === "monthly");
      } else if (location.pathname.indexOf("/three-month") !== -1) {
        product = products.find((p) => p.description === "three month");
      }

      if (!!product) {
        sagaMiddleware.run<any>(
          addProductStore,
          {
            book_id: null,
            babypage_id: null,
            event: "",
            gift: {
              status: false,
              email: "",
              receiver: "",
              message: "",
              date: "",
            },
            id: product.stripe_id,
            item: product.description,
            description: product.description,
            options: subscriptionOptions.map((e) => {
              if (e.description === product!.description)
                return { ...e, selected: true };
              return e;
            }),
            prices: { monthly: 7.99, "three month": 13.98, "one year": 45 },
            qty: 1,
            stripe_id: product.stripe_id,
            subtitle: `BabyPage ${product.title}`,
            thumbnail: "",
            title: "BabyPage PLUS Subscription",
            total: product.price / 100,
            type: product.type,
          },
          (error?: string, _?: string) => {
            if (!!error) {
              Modal.error({
                title: "Error",
                content: error,
              });
            } else {
              if (user.source === "baby-list") {
                history.push("/checkout");
              }
              setLoading(false);
            }
          },
        );
      }
    }
  }, [user.user_level, location.pathname, products]);

  const subTotal = useMemo(() => {
    return store.reduce((total: number, item: any) => {
      // Calculate total without extra pages
      const totalWithoutExtraPages = item.options.reduce(
        (acc: any, current: any) => {
          return acc + current.price * current.qty;
        },
        0,
      );

      // Calculate total for extra pages
      const totalExtraPages = item.options.reduce((acc: any, current: any) => {
        if (
          current.selected &&
          current.extra_pages &&
          current.extra_pages.selected
        ) {
          return acc + current.extra_pages.price * current.extra_pages.qty;
        }
        return acc;
      }, 0);

      // Combine both totals
      return total + totalWithoutExtraPages + totalExtraPages;
    }, 0);
  }, [store]);

  const subscription = useMemo(
    () => store.find((item) => item.type === "subscription"),
    [store],
  );

  const updateStore = useCallback(
    (newStore: CartItem[]) => {
      if (debounce !== null) {
        clearTimeout(debounce);
      }

      setDebounce(
        setTimeout(() => {
          sagaMiddleware.run<any>(
            updateUserStore,
            JSON.stringify(newStore),
            (error?: string, description?: string) => {
              if (!!error) {
                Modal.error({
                  title: "Error",
                  content: error,
                });
              }
            },
          );
        }, 3000),
      );
    },
    [debounce],
  );

  const onRemoveItem = useCallback(
    (index: number) => {
      confirm({
        title: "Are you sure you want to delete this item?",
        okType: "danger",
        width: 300,
        icon: "warning",
        onOk() {
          if (!!debounce) {
            clearTimeout(debounce);
            setDebounce(null);
          }

          setLoading(true);
          const clonedStore = cloneDeep(store);
          clonedStore.splice(index, 1);

          sagaMiddleware.run<any>(
            updateUserStore,
            JSON.stringify(clonedStore),
            (error?: string, description?: string) => {
              setLoading(false);

              if (!!error) {
                Modal.error({
                  title: "Error",
                  content: error,
                });
              } else if (!!description) {
                notification.success({
                  message: "Success",
                  description,
                });
              }
            },
          );
        },
      });
    },
    [store, debounce],
  );

  const onCheckout = useCallback(() => {
    const itemInvalid = store.find((item) => {
      if (
        item.type === "giftcard" &&
        (!item.gift.receiver ||
          !item.gift.email ||
          !isEmailValid(item.gift.email))
      ) {
        info({
          title:
            "Please fill your gift card information with a valid name and email.",
        });

        return true;
      } else if (
        item.type === "book" &&
        !item.options.find((o) => !!o.selected)
      ) {
        info({
          title:
            "You have a book without selecting any options, if you don't want it, remove it from the cart.",
        });

        return true;
      }
      return false;
    });

    if (itemInvalid) return;

    // Ensure the giftcard value is valid
    const userStore = store.map((i) => {
      if (i.type === "giftcard") {
        if (i.total < 10) {
          i.total = 10;
        } else if (i.total > 300) {
          i.total = 300;
        }
      }

      return i;
    });

    onChangeUser({ store: JSON.stringify(userStore) });

    setTimeout(() => {
      history.push("/checkout");
    }, 100);
  }, [store, history, onChangeUser]);

  return (
    <BaseLayout
      title="BabyPage - Cart"
      pageTitle="My Cart"
      pageSubtitle="Checkout"
      hideChildMenu
      pageStyle={{ backgroundColor: "#f0f0f0" }}
      hideHeaderChildMenu
    >
      {loading ? (
        <div className="cart-full d-flex justify-content-center align-items-center">
          <Icon type="loading" style={{ fontSize: 36 }} />
        </div>
      ) : store.length > 0 ? (
        <div className="container my-md-4 mt-0 mb-5">
          <div className="row justify-content-start align-items-center mx-md-4">
            <div className="col-12 col-lg-6 col-md-7 cart-container">
              {store.map((item, index) =>
                item.type === "giftcard" ? (
                  <div key={index + "div"}>
                    <GiftCard
                      key={index}
                      item={item}
                      onChangeItem={(item) => {
                        const newStore = store.map((e, i) => {
                          if (i === index) return item;
                          return e;
                        });

                        setStore(newStore);
                        updateStore(newStore);
                      }}
                      onRemoveItem={() => onRemoveItem(index)}
                      itemIndex={index}
                      addGiftCard={addGiftCard}
                    />
                  </div>
                ) : item.type === "book" ? (
                  <Book
                    key={index}
                    item={item}
                    onChangeItem={(item) => {
                      const newStore = store.map((e, i) => {
                        if (i === index) return item;
                        return e;
                      });

                      const filteredStored = newStore.map(
                        ({ babypages, ...keepAttrs }) => keepAttrs,
                      );
                      setStore(filteredStored);
                      updateStore(filteredStored);
                    }}
                    onRemoveItem={() => onRemoveItem(index)}
                    itemIndex={index}
                    setLoading={setLoading}
                  />
                ) : item.type === "subscription" ? (
                  <Subscription
                    key={index}
                    item={item}
                    onChangeItem={(item) => {
                      const newStore = store.map((e, i) => {
                        if (i === index) return item;
                        return e;
                      });

                      setStore(newStore);
                      updateStore(newStore);
                    }}
                    onRemoveItem={() => onRemoveItem(index)}
                    user={user}
                    itemIndex={index}
                  />
                ) : (
                  <BabyPrint
                    key={index}
                    item={item}
                    itemIndex={index}
                    store={store}
                    setStore={setStore}
                    updateStore={updateStore}
                    onRemoveItem={onRemoveItem}
                  />
                ),
              )}
            </div>
          </div>
          <div className={`button-bar ${cartSummaryMarginClasses}`}>
            <div className="row">
              <div className="col-6 col-md-4 col-lg-3">
                <h3 className="cart-summary">Summary:</h3>
              </div>
              <div className="d-flex justify-content-between w-100">
                <h3 className="cart-subtotal w-50 ml-3">Subtotal:</h3>
                <span className="mr-3  ">
                  <strong className="cart-subtotal">
                    ${" "}
                    {subscription && !user.trialUsed
                      ? (subTotal - subscription.total).toFixed(2)
                      : subTotal.toFixed(2)}
                  </strong>
                  {!!subscription && !user.trialUsed && (
                    <Tooltip
                      title={`You have a PLUS subscription in your cart, but you will not be charged for it until your 7-day free trial period expires.`}
                    >
                      <Icon style={{ marginLeft: 5 }} type="info-circle" />
                    </Tooltip>
                  )}
                </span>
              </div>
              {!!subscription && !user.trialUsed && (
                <div className="d-flex w-100 justify-content-center total-to-be-charged mx-3">
                  <p>
                    Your subscription total{" "}
                    <strong>${subscription.total}</strong> will be charged on{" "}
                    <strong>{getDayForSubscription()}</strong>.
                  </p>
                </div>
              )}

              <div className="col-md-12 col-10 mx-auto d-flex">
                <Button
                  type="primary"
                  className="w-100 green-button checkout-button mt-3"
                  ghost
                  shape="round"
                  onClick={onCheckout}
                  disabled={store.length === 0 || loading}
                >
                  Proceed to Checkout
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="cart-full d-flex justify-content-center align-items-center flex-column">
          <Icon type="shopping-cart" style={{ fontSize: 36 }} />
          <h3 className="my-1">
            <strong>Shopping Cart Empty</strong>
          </h3>
        </div>
      )}
    </BaseLayout>
  );
};

const mapStateToProps = (state: AppState) => ({
  user: state.user,
  products: state.product.products,
  stripeProducts: state.stripeProduct,
});

const mapDispatchToProps = (dispatch: Dispatch<BaseAction>) => ({
  onChangeUser: (payload: any) => dispatch(changeUser(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartPage);
